import React from "react";
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckSquare } from "@fortawesome/free-solid-svg-icons/faCheckSquare";

const AirFreight = ({ days, msg, badge }) => {
  return (
    <>
      <Header>
        <FontAwesomeIcon icon={faCheckSquare} color={"orange"} />
        <StyledStock>{msg}</StyledStock>
      </Header>
      <HeaderInfo>
        <StyledAvl>{days}</StyledAvl>
        <InfoText dangerouslySetInnerHTML={{ __html: badge }} />
      </HeaderInfo>
    </>
  );
};

export default AirFreight;

const Header = styled.div`
  display: flex;
  font-size: 20px;
  align-items: center;
`;

const HeaderInfo = styled.div`
  display: flex;
  flex-direction: column;
  font-size: 20px;
  align-items: flex-start;
`;

const StyledStock = styled.div`
  color: orange;
  margin-left: 5px;
  font-weight: 700;
  font-family: "Open Sans", sans-serif;
`;

const StyledAvl = styled.div`
  font-family: "Open Sans", sans-serif;
  font-size: 16px;
  color: rgb(102, 102, 102);
`;

const InfoText = styled.small`
  font-family: "Open Sans", sans-serif;
  color: #666;
  font-size: 12px;
  line-height: 1.4;
  font-style: italic;
`;
