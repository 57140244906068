import React, { useState, useEffect } from "react";

import CookieConsentBanner from "../CookieConsentBanner/CookieConsentBanner";
import CookieManager from "./../CookieManger/CookieManager";
import { useConsentContext } from "./../../../context/ConstentContext";
import { useAppContext } from "./../../../context/AppContext";
import {
  loadHotJarScript,
  loadLiveAgentScript,
} from "./../../../scripts/index";

export const CookieContainer = () => {
  const { cookiesConsentCtx, setCookiesConsentCtx } = useConsentContext();
  const { initialAction, setShowCookiesManager } = useAppContext();
  const [showCookieConsentBanner, setShowCookieConsentBanner] = useState(false);

  const handleCookieConsentResponse = (response) => {
    if (response === "manage") {
      setShowCookiesManager(true);
    } else {
      acceptAll();
    }
  };

  const acceptAll = () => {
    saveCookiePreferences(true, [
      "Niezbędne",
      "Funkcjonalne",
      "Statystyczne",
      "Marketingowe",
    ]);
  };

  const handleCookieSettings = (response) => {
    const preferences = response
      .filter((pref) => pref.enabled)
      .map((pref) => pref.label);

    saveCookiePreferences(true, preferences);
    setShowCookiesManager(false);
  };

  const saveCookiePreferences = (accepted, preferences) => {
    setCookiesConsentCtx({ accepted, preferences });
  };

  const cookieHandler = () => {
    if (cookiesConsentCtx.accepted) {
      setShowCookieConsentBanner(false);
    } else {
      if (!cookiesConsentCtx.accepted) {
        setShowCookieConsentBanner(true);
      }
    }
  };

  const loadScriptsBasedOnConsent = () => {
    const hasAnalytics =
      cookiesConsentCtx.preferences &&
      cookiesConsentCtx.preferences.includes("Statystyczne");

    const hasFunctional =
      cookiesConsentCtx.preferences &&
      cookiesConsentCtx.preferences.includes("Funkcjonalne");

    const hasAdvertisement =
      cookiesConsentCtx.preferences &&
      cookiesConsentCtx.preferences.includes("Marketingowe");

    if (hasAnalytics) {
      loadHotJarScript();
    }

    if (hasFunctional) {
      loadLiveAgentScript();
    }

    window.gtag("consent", "update", {
      ad_storage: hasAdvertisement ? "granted" : "denied",
      ad_user_data: hasAdvertisement ? "granted" : "denied",
      ad_personalization: hasAdvertisement ? "granted" : "denied",
      analytics_storage: hasAnalytics ? "granted" : "denied",
    });

    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      event: "consent_update",
    });
  };

  useEffect(() => {
    if (initialAction) {
      cookieHandler();
      loadScriptsBasedOnConsent();
    }
  }, [cookiesConsentCtx, initialAction]);

  return (
    <>
      {showCookieConsentBanner && (
        <CookieConsentBanner
          onCookieConsentResponse={handleCookieConsentResponse}
        />
      )}
      {<CookieManager onSavePreferences={handleCookieSettings} />}
    </>
  );
};

export default CookieContainer;
