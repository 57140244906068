import React from "react";
import InStock from "../InStock/InStock";
import OutOfStock from "../OutOfStock/OutOfStock";
import AirFreight from "../AirFreight/AirFreight";
import InTransit from "../InTransit/InTransit";
import styled from "styled-components";

const Availability = ({ availability, quantity, basketQuantity }) => {
  const { av, max } = availability;

  if (quantity + basketQuantity > max + basketQuantity) {
    return <OutOfStock />;
  }

  switch (av.case) {
    case "instock":
      return (
        <StyledAvailability>
          <InStock msg={av.message} days={av.days} />
        </StyledAvailability>
      );

    case "dropshipping":
      return (
        <StyledAvailability>
          <InStock msg={av.message} days={av.days} />
        </StyledAvailability>
      );

    case "alternative":
      return (
        <StyledAvailability>
          <InStock msg={av.message} days={av.days} />
        </StyledAvailability>
      );

    case "intransit":
      return (
        <StyledAvailability>
          <InTransit msg={av.message} days={av.days} />
        </StyledAvailability>
      );

    case "airfreight":
      return (
        <StyledAvailability>
          <AirFreight msg={av.message} days={av.days} badge={av.badgeText} />
        </StyledAvailability>
      );

    default:
      return (
        <div className="availability-unknown">
          <span className="availability-message">Status unavailable</span>
        </div>
      );
  }
};

export default Availability;

const StyledAvailability = styled.div`
  margin: 30px 0;
`;
