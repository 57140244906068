import React from "react";
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckSquare } from "@fortawesome/free-solid-svg-icons/faCheckSquare";

const InTransit = ({ days, msg }) => {
  return (
    <>
      <Header>
        <FontAwesomeIcon icon={faCheckSquare} color={"orange"} />
        <StyledStock>{msg}</StyledStock>
      </Header>
      <StyledAvl>{days}</StyledAvl>
    </>
  );
};
export default InTransit;

const Header = styled.div`
  display: flex;
  font-size: 20px;
  gap: 5px;
  align-items: center;
`;

const StyledStock = styled.div`
  color: orange;
  font-weight: 700;
  font-family: "Open Sans", sans-serif;
`;

const StyledAvl = styled.div`
  font-family: "Open Sans", sans-serif;
  font-size: 16px;
  color: rgb(102, 102, 102);
`;
