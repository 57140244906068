import React, { useEffect, useState } from "react";
import styled from "styled-components";
import styles from "./BasketLine.module.scss";
import plus from "./../../../assets/qty-plus.svg";
import minus from "./../../../assets/qty-minus.svg";
import { Link } from "react-router-dom";
import { PRIMARY } from "../../../vars/vars";
import { Spinner } from "react-bootstrap";
import placeholderWebp from "../../../assets/placeholders/pedders_placeholder.webp";
import placeholder from "../../../assets/placeholders/pedders_placeholder.jpg";
import { useAppContext } from "../../../context/AppContext";
import DetailsInputButtonInBasket from "../../atoms/DetailsInputButtonInBasket/DetailsInputButtonInBasket";
import { getItemData } from "../../../axios/api";
import { getAvailability } from "../../../vars/availability";

const BasketLine = ({
  item,
  currency,
  changeHanlder,
  deleteHandler,
  isLoading,
}) => {
  const [quantity, setQuantity] = useState(item.quantity);
  const { basketId, userCountry } = useAppContext();
  const [max, setMax] = useState(1);

  const onChangeHandler = async () => {
    changeHanlder(quantity, item._id, basketId);
  };

  useEffect(() => {
    onChangeHandler();
  }, [quantity]);

  useEffect(() => {
    async function fetch(item, userCountry) {
      const [{ data: part }] = await getItemData(
        item.item.urlcomponent,
        userCountry.code
      );

      const { max } = getAvailability(part, quantity, 0);

      setMax(max);
    }
    fetch(item, userCountry);
  }, []);

  const handleClick = (type) => {
    switch (type) {
      case "plus":
        if (quantity + 1 > max) {
          break;
        }
        setQuantity(quantity + 1);
        break;
      case "minus":
        if (quantity - 1 === 0) {
          break;
        }
        setQuantity(quantity - 1);
        break;
      default:
        break;
    }
  };
  return (
    <>
      <DescriptionWrapper>
        <Photo to={`/details/${item.item.urlcomponent}`}>
          {item.item.photos.length ? (
            <img src={item.item.photos[0].url} alt={item.displayname} />
          ) : (
            <picture>
              <source srcSet={placeholderWebp} type="image/webp" />
              <img src={placeholder} alt={item.displayname} />
            </picture>
          )}
        </Photo>
        <div>
          <Description to={`/details/${item.item.urlcomponent}`}>
            {item.item.description}
            <br />
            {item.displayname}
          </Description>
        </div>
      </DescriptionWrapper>
      <Price>
        <span>Cena:</span>
        {item.grossprice || item.price} {currency}
      </Price>

      <InputWrapper>
        <span>Ilość:</span>
        <StyledInputArea>
          <DetailsInputButtonInBasket
            image={minus}
            name={"minus"}
            clickHandler={handleClick}
          />
          <StyledInput
            className={styles.Input}
            disabled
            type={"number"}
            value={quantity}
            min={"1"}
            max={max}
          />
          <DetailsInputButtonInBasket
            image={plus}
            name={"plus"}
            clickHandler={handleClick}
          />
        </StyledInputArea>
      </InputWrapper>

      <Price>
        <span>Suma częściowa:</span>
        {item.amount || item.grosamount} {currency}
      </Price>

      <Buttons>
        <Button disabled={isLoading} onClick={() => deleteHandler(item._id)}>
          {isLoading ? (
            <Spinner
              animation="border"
              color={"white"}
              size={"sm"}
              role="status"
            />
          ) : (
            "Usuń"
          )}
        </Button>
      </Buttons>
    </>
  );
};

export default BasketLine;

const Button = styled.button`
  border: 1px solid #cdcdcd;
  background-color: white;
  color: #333;
  min-width: 100px;
  font-size: 12px;
  padding: 7px 0;

  &:hover {
    color: white;
    background-color: ${PRIMARY};
  }
`;

const Buttons = styled.div`
  grid-column: 1 / 5;
  margin-top: 20px;
  border-top: 1px solid #dee2e6;
  border-bottom: 1px solid #dee2e6;
  padding: 8px 8px 20px 8px;

  @media (min-width: 767px) {
    padding: 8px 8px 20px 8px;
    margin-top: 0px;
  }
`;

const InputWrapper = styled.div`
  grid-column: 1 / 5;
  display: flex;
  justify-content: space-between;
  padding: 10px 8px 0;
  text-align: right;
  align-items: center;

  span {
    font-family: "Open Sans", sans-serif;
    font-weight: 700;
    color: #666666;
    font-size: 16px;
  }

  @media (min-width: 767px) {
    grid-column: auto;
    padding: 10px 0 0;
    display: block;
    margin-left: auto;
    span {
      display: none;
    }
  }
`;

const Price = styled.div`
  grid-column: 1 / 5;
  display: flex;
  justify-content: space-between;
  font-family: "Open Sans", sans-serif;
  font-weight: 700;
  color: #666666;
  font-size: 16px;
  vertical-align: top;
  padding: 10px 8px 0;
  text-align: right;

  @media (min-width: 767px) {
    padding: 10px 0px 0;
    grid-column: auto;
    display: block;
    font-size: 17px;

    span {
      display: none;
    }
  }
`;

const Description = styled(Link)`
  flex-basis: 60%;
  margin-bottom: 16px;
  display: inline-block;
  font-size: 14px;
  width: 100%;
  text-align: center;
  color: #444444;
  font-family: "Open Sans", sans-serif;
  &:hover {
    color: ${PRIMARY};
  }

  @media (min-width: 767px) {
    text-align: left;
    font-size: 15px;
  }
`;

const DescriptionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  grid-column: 1 / 5;
  gap: 5px;
  font-size: 12px;
  padding: 10px 0;

  @media (min-width: 767px) {
    flex-direction: row;
    grid-column: auto;
  }
`;

const Photo = styled(Link)`
  img {
    width: 100%;
    height: 150px;
    object-fit: contain;
    border: 1px solid #ddd;
    &:hover {
      border-color: ${PRIMARY};
    }
  }

  @media (min-width: 767px) {
    img {
      width: 150px;
      margin-right: 10px;
    }
  }
`;

const StyledInputArea = styled.div`
  display: flex;
  @media (min-width: 767px) {
    margin-top: 2px;
  }
`;

const StyledInput = styled.input`
  font-family: "Open Sans", sans-serif;
  font-size: 15px;
  height: 20px;
  width: 30px;
  padding: 2px;
  text-align: center;

  &:focus {
    outline: none;
  }
`;
