import React from "react";
import styled from "styled-components";
import { useHistory } from "react-router-dom";

const Applications = ({ applications }) => {
  const history = useHistory();
  const handleRowClick = (item) => {
    const url = new URL("parts", window.location);
    url.searchParams.append("make", item.make);
    url.searchParams.append("year", item.year);
    url.searchParams.append("model", item.model);

    history.push(`/parts${url.search}`);
  };

  return (
    <StyledTable>
      <thead>
        <tr>
          <TableHeader>Marka</TableHeader>
          <TableHeader>Model</TableHeader>
          <TableHeader>Rok</TableHeader>
          <TableHeader>Grupa</TableHeader>
          <TableHeader>Parametry</TableHeader>
        </tr>
      </thead>
      <tbody>
        {applications.map((item, k) => (
          <TableRow key={k} onClick={() => handleRowClick(item)}>
            <TableBody data-label="Make">{item.make}</TableBody>
            <TableBody data-label="Model">{item.model}</TableBody>
            <TableBody data-label="Year">{item.year}</TableBody>
            <TableBody data-label="Group">{item.groupdescription}</TableBody>
            <TableBody data-label="Parameters">
              <Parameter>{item.parameter}</Parameter>
              <Parameter>{item.parameter2}</Parameter>
              <Parameter>{item.parameter3}</Parameter>
            </TableBody>
          </TableRow>
        ))}
      </tbody>
    </StyledTable>
  );
};

export default Applications;

const StyledTable = styled.table`
  width: 100%;
  margin-bottom: 16px;

  @media (max-width: 768px) {
    display: block;

    thead {
      display: none;
    }

    tbody {
      display: block;
    }
  }
`;

const TableHeader = styled.th`
  vertical-align: bottom;
  border-bottom: 2px solid #dee2e6;
  border-top: 1px solid #dee2e6;
  padding: 12px 8px;
  font-size: 14px;
`;

const TableRow = styled.tr`
  cursor: pointer;

  @media (max-width: 768px) {
    display: block;
    margin-bottom: 1rem;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    border: 1px solid #dee2e6;
    padding: 0.8rem;
    background-color: white;
  }
`;

const TableBody = styled.td`
  border-top: 1px solid #dee2e6;
  font-size: 13px;
  padding: 10px 6px;

  @media (max-width: 768px) {
    display: block;
    border: none;
    padding: 0.5rem 0;
    position: relative;
    padding-left: 120px;
    min-height: 30px;

    &:before {
      content: attr(data-label);
      font-weight: 600;
      color: #666;
      position: absolute;
      left: 0;
      width: 110px;
    }

    &:not(:last-child) {
      border-bottom: 1px solid #eee;
    }
  }
`;

const Parameter = styled.p`
  margin-bottom: 8px;

  @media (max-width: 768px) {
    margin-bottom: 4px;

    &:last-child {
      margin-bottom: 0;
    }
  }
`;
