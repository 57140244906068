export const PRIMARY = "#dc3332";
export const SECONDARY = "#666666";
export const BREAKPOINT = "576px";
export const SOURCE = "pedderspl";
export const CURRENCY = "pln";
export const CUSTOMER = "guest";
export const TAX = "5e0dbaba9e33df43f0b3a47f";
export const TAX_FLOAT = 1.23;
export const PAYPAL_BASE =
  "https://www.paypal.com/sdk/js?currency=PLN&disable-funding=card&client-id=";
export const PAYPAL_SANDBOX =
  "AUnK_LGFj8QlZOUcmvlzQkDrH_rM5q2RUnYJBAvTlrDgcCuu6S7blC236LfvYNIgRm11kUQslqMz3NBh";
export const PAYPAL_LIVE =
  "Abr3Oy3T51DwdxwyKNM2X3uOiuyVAcQ9iZnnO93CY7riR-AOQsxDEs7wpIcH3mZIs1Rnda4DkAFJNKe5";

export const TAX_OBJ = {
  ZERO: "5e0dbaba9e33df43f0b3a480",
  EU: "5e0dbaba9e33df43f0b3a47f", // 23%
};
export const TAX_FLOAT_OBJ = {
  ZERO: 1,
  EU: 1.23,
};

export const ACTION = {
  CREATE_LEAD: "addCustomer",
  GET_CARTS: "getCarts",
  ADD_TO_CART: "addToCart",
  UPDATE_CART: "updateCart",
  GET_PAYMENT_METHODS: "getPaymentMethods",
};

export const LOCATION = {
  EU: "5e0dbaba9e33df43f0b3a495",
};

export const WAREHOUSE_LOCATION = {
  UK: "61e14c6ae4a35a668517a8fe",
  PL: "5e0dbaba9e33df43f0b3a495",
  NL: "66e04fcba16b2e1e7d873be8",
};

export const PAYMENT_METHODS = {
  PAYPAL: "607468324e5b797a767fe87d",
  REVOLUT: "revolut",
};

export const deliveryTime = {
  IN_STOCK: "Dostawa w ciągu 2-4 dni",
  IN_TRANSIT: "Produkt w trakcie transportu - dostawa 2-3 tygodnie",
  IN_MANUFACTURER: "Dostawa w przeciągu 4 tygodni",
};

export const WIDGET_TYPE = {
  REVOLUT: "Revolut",
  PAYPAL: "PayPal",
};

export const HOTJAR_PL = 5091074;

export const GTM_PL = "GTM-KH75T6X";

export const COOKIESNAME = "pedders-pl-cookies-consents";
